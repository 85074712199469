@import "../../assets/styles/utils";
footer {
    @include res(padding-top,50px,(2 / 5));
    @include res(padding-bottom,15px);
    background-color: #606d79;
    .title {
        width: fit-content;
        margin: auto;
        font-family: AlibabaPuHuiTiL;
        @include res(font-size,24px,(5 / 6));
        letter-spacing: 1px;
	    color: #ffffff;
    }
    ul {
        @include res(margin-top,35px,(20 / 35));
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @include res(flex-wrap,nowrap,(xs:wrap));
        li {
            font-family: AlibabaPuHuiTiL;
            letter-spacing: 1px;
            color: #ffffff;
            line-height: 24px;
            @include res(width,fit-content,(xs:100%));
            @include res(margin-bottom,0,(xs:20px));
            .pic {
                width: fit-content;
                height: 50px;
                margin: auto;
                @include res(margin-bottom,24px,(15 / 24));
            }
            p {
                @include res(font-size,18px,(16 / 18));
                @include res(margin-bottom,20px,(15 / 20));
                @include res(text-align,left,(xs:center));
            }
            span {
                display: flex;
                font-size: 14px;
                align-items: center;
                @include res(justify-content,flex-start,(xs:center));
                img {
                    margin-right: 12px;
                }
            }
        }
        .line {
            width: 1px;
            @include res(height,160px);
            background: #fff;
            opacity: .15;
            @include res(display,block,(xs:none));
            &:last-child {
                display: none;
            }
        }
    }
    .bottom {
        @include res(margin-top,50px,(2 / 5));
        .footer-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: block;
                width: 100%;
                height: 1px;
                background: rgba(255,255,255,.15);
            }
            img {
                margin: 0 20px;
            }
        }
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: AlibabaPuHuiTiL;
	        font-size: 12px;
            color: rgba(255,255,255,.5);
            flex-wrap: wrap;
            .copy {
                width: fit-content;
                @include res(margin,0,(xs:auto));
                @include res(margin-bottom,0,(xs:10px));
                @include res(margin-top,0,(xs:10px));
            }
            .right {
                width: fit-content;
                @include res(margin,0,(xs:auto));
            }
            a {
                transition: all .3s;
                &:first-child {
                    margin-right: 15px;
                }
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}