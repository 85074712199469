@import "../../assets/styles/utils";
header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    @include res(height,118px,(xs:60px));
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            img {
                @include res(width,fit-content,(xs:65%));
            }
        }
        .nav {
            @include res(display,flex,(sm:none));
            a {
                color: #303030;
                font-family: AlibabaPuHuiTiL;
                @include res(font-size,20px,(4 / 5));
                transition: all .3s;
                height: 100%;
                @include res(margin-right,85px,(lg:30px));
                &:last-child {
                    margin-right: 0;
                }
                span {
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &:after {
                        content: "";
                        width: 100%;
                        height: 2px;
                        display: block;
                        background-color: #1c419d;
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        z-index: 1;
                        transition: all .3s;
                        transform-origin: center;
                        transform: scaleX(0);
                    }
                }
                &:hover,&.active {
                    color: #1c419d;
                    font-family: AlibabaPuHuiTiR;
                    span {
                        &:after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .search {
                cursor: pointer;
                @include res(margin-left,65px,(lg:30px));
            }
            .language {
                display: flex;
                align-items: center;
                @include res(margin-left,42px,(lg:20px));
                a {
                    display: block;
                    font-family: AlibabaPuHuiTiL;
                    transition: all .3s;
                    @include res(font-size,20px,(16 / 20));
                    color: #303030;
                    &:hover,&.active {
                        font-family: AlibabaPuHuiTiR;
                        color: #1c419d;
                    }
                }
                span {
                    display: block;
                    width: 1px;
                    height: 15px;
                    background-color: #bfbfbf;
                    margin: 0 10px;
                }
            }
        }
        .open-menu {
            @include res(display,none,(sm:block));
            margin-left: 20px;
            position: relative;
            z-index: 100;
            // top: 50%;
            // transform: translateY(-50%);
            // right: 20px;
        }
        .mob-nav {
            width: 100%;
            height: 100vh;
            background-color: #f7f7f7;
            position: fixed;
            top: 70px;
            right: 100%;
            z-index: 99;
            color: #303030;
            box-sizing: border-box;
            overflow-y: auto;
            transition: .4s cubic-bezier(.77,0,.175,1);
            padding-top: 30px;
            .nLi {
                &.active {
                    img {
                        transform: rotate(90deg);
                    }
                }
                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgba(0,73,130,.3);
                    margin: 0 20px;
                    // padding: 0 20px;
                    .arrow {
                        width: 45px;
                        display: flex;
                        justify-content: end;
                    }
                    a {
                        flex-grow: 1;
                        line-height: 45px;
                        font-size: 16px;
                    }
                }
            }
            &.active {
                right: 0;
            }
            .sub {
                display: none;
                border-bottom: 1px solid rgba(0,73,130,.3);
                li {
                    a {
                        display: block;
                        padding-left: 20px;
                        font-size: 16px;
                        line-height: 40px;
                        color: #303030;
                    }
                }
            }
        }
    }
}
.search-xl {
    display: none;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include res(height,500px,(xs:300px));
    background: transparent;
    z-index: 10;
    .content {
        @include res(width,900px,(sm:98%));
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        form {
            width: 100%;
            @include res(margin-top,25px);
            position: relative;
            input {
                width: 100%;
                border: none;
                border-bottom: 1px solid #a0a0a0;
                outline: none;
                background: transparent;
                font-family: AlibabaPuHuiTiL;
                @include res(font-size,32px,(20 / 24));
                padding-bottom: 20px;
                @include res(letter-spacing,3px,(1 / 3));
                color: #303030;
                &::placeholder {
                    font-family: AlibabaPuHuiTiL;
                    @include res(font-size,32px,(20 / 24));
                    @include res(letter-spacing,3px,(1 / 3));
                    color: #303030;
                }
            }
            a {
                position: absolute;
                right: 0;
                bottom: 15px;
            }
        }
        
    }
}