@font-face {
  font-family: "iconfont"; /* Project id 4238437 */
  src: url('iconfont.woff2?t=1696907847040') format('woff2'),
       url('iconfont.woff?t=1696907847040') format('woff'),
       url('iconfont.ttf?t=1696907847040') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right1:before {
  content: "\e609";
}

.icon-youjiantou:before {
  content: "\e624";
}

.icon-left:before {
  content: "\e83d";
}

.icon-right:before {
  content: "\e840";
}

