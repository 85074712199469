@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'AlibabaPuHuiTiB';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AlibabaPuHuiTiL';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AlibabaPuHuiTiH';
  src: url('https://ziti.dongbaqu.com/AlibabaPuHuiTi-3-105-Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SourceHanSansCN-Medium';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AlibabaPuHuiTiR';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PangMenZhengDao';
  src: url('https://ziti.dongbaqu.com/PangMenZhengDao.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OriginalSurfer-Regular';
  src: url('../fonts/OriginalSurfer-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'AlibabaPuHuiTiR';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
html {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #AAAAAA;
  }
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: fade(#fff, 30%);
  }
}
img {
  max-width: fit-content;
  display: block;
  width: 100%;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1200px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1200px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
.swiper-button-next {
  color: #fff;
  &:after {
    content:"";
  }
}
.swiper-button-prev {
  color: #fff;
  &:after {
    content:"";
  }
}
.nei-banner {
  position: relative;
  @include res(height,auto,(xs:300px));
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    position: absolute;
    @include res(top,3.28rem,(xs:150px));
    left: 0;
    right: 0;
    .title {
      h2 {
        font-family: PangMenZhengDao;
        @include res(font-size,46px,(30 / 46));
        color: #1c419d;
        letter-spacing: 2px;
      }
      p {
        @include res(margin-left,60px,(2 / 3));
        display: flex;
        align-items: center;
        font-family: AlibabaPuHuiTiL;
        letter-spacing: 2px;
	      color: #1c419d;
        @include res(font-size,20px,(18 / 20));
        span {
          display: block;
          width: 20px;
          height: 1px;
          background: #1c419d;
          margin-right: 15px;
        }

      }
    }
  }
}
.nei-navbox {
  @include res(margin-top,30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nei-nav {
    display: flex;
    align-items: center;
    font-family: AlibabaPuHuiTiL;
    font-size: 14px;
    color: #303030;
    a {
      transition: all .3s;
      &:hover,&.active {
        color: #1c419d;
      }
    }
    span {
      margin: 0 10px;
    }
  }
  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: AlibabaPuHuiTiL;
	  font-size: 14px;
    color: #303030;
    transition: all .3s;
    &:hover {
      color: #1c419d;
    }
    img {
      margin-right: 8px;
    }
  }
}
.page {
  width: fit-content;
  margin: auto;
  @include res(margin-top,80px,(1 / 2));
  a {
    font-family: AlibabaPuHuiTiL;
    letter-spacing: 2px;
    color: #303030;
    transition: all .3s;
    width: 16px;
    font-size: 18px;
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
    @include res(margin-right,40px,(1 / 2));
    &:last-child {
      margin-right: 0;
    }
    &:hover,&.active {
      font-family: AlibabaPuHuiTiR;
      color: #1c419d;
      border-color: #1c419d;
    }
    &:first-child,&:last-child {
      &:hover {
        color: #1c419d;
        border-color: transparent;
      }
    }
  }
}